import { combineReducers } from 'redux';
import {
  ACCEPT_LOADING,
  ACCEPT_ITEM
} from "constants/accept";

export function loading(state = true, action) {
    switch (action.type) {
        case ACCEPT_LOADING:
            return action.payload;
        default:
            return state;
    }
}
export function item(state = [], action) {
    switch (action.type) {
        case ACCEPT_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export const acceptReducers = () => combineReducers({
    loading: loading,
    item: item
});
