import axios from "axios";
import { ADMINS_LIST, ADMINS_ITEM, ADMINS_ADD } from "constants/admins";
import { API_URL } from "config/conf";
import * as message from "actions/messages";
import { history } from "store";

const API_SECTION = "users";

export function fetchList(params) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}`, { params: params })
      .then((response) => {
        dispatch({ type: ADMINS_LIST, payload: response.data.data });
      });
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`).then((response) => {
      dispatch({ type: ADMINS_ITEM, payload: response.data.data });
    });
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item).then((response) => {
      dispatch({ type: ADMINS_ADD, payload: response.data.data });
      dispatch(message.success("Администратор создан"));
      history.push("/admins");
    });
  };
}

export function updateItem(item) {
  return (dispatch) => {
    axios
      .patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({ type: ADMINS_ITEM, payload: response.data.data });
        dispatch(message.success("Администратор обновлен"));
      });
  };
}
