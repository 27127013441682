import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Form, Select, Table, Space } from "antd";
import { addTrainerPlan } from "actions/trainers";

const TrainersPlans = () => {
  const item = useSelector((state) => state.trainers.item);
  const plans = useSelector((state) => state.plans.active);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = (values) => {
    dispatch(addTrainerPlan(item.id, values));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const PlanList = () => {
    const columns = [
      {
        title: "Имя",
        dataIndex: "name",
        key: "name",
        render: (text) => <div>{text}</div>,
      },
    ];

    return (
      <Table
        className={"data-table"}
        pagination={false}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={item.plans}
        locale={{ emptyText: "Нет данных" }}
        onRow={({ id }) => ({
          onClick: () => {},
        })}
      />
    );
  };

  return (
    <>
      <Space
        style={{
          marginBottom: 14,
        }}
      >
        <Button type="primary" onClick={() => showModal()}>
          Добавить
        </Button>
      </Space>
      <PlanList />
      <Modal
        title="Add plan"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          onFinish={handleOk}
          autoComplete="off"
          form={form}
          labelCol={{
            span: 4,
          }}
        >
          <Form.Item
            label="Plan"
            name="plan_id"
            rules={[
              {
                required: true,
                message: "Выберите клуб",
              },
            ]}
          >
            <Select>
              {plans.map((item, key) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TrainersPlans;
