import axios from 'axios';
import {
  CLIENTS_LIST,
  CLIENTS_ITEM,
  CLIENTS_ADD,
  CLIENTS_SEARCH_LIST
} from "constants/clients";
import { API_URL } from 'config/conf';
import * as message from 'actions/messages';
import { history } from "store";

const API_SECTION = "users";

export function fetchList(params) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`, { params: params})
      .then((response) => {
          dispatch({type: CLIENTS_LIST, payload: response.data.data});
      })
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`)
      .then((response) => {
        dispatch({type: CLIENTS_ITEM, payload: response.data.data});
      })
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item)
      .then((response) => {
        dispatch({type: CLIENTS_ADD, payload: response.data.data});
        dispatch(message.success("Клиент создан"))
        history.push("/clients")
      })
  }
}

export function updateItem(item) {
  return (dispatch) => {
    axios.patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({type: CLIENTS_ITEM, payload: response.data.data});
        dispatch(message.success("Клиент обновлен"))
      })
  }
}

export function search(value) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`, {params: {q: value}})
      .then((response) => {
        dispatch({type: CLIENTS_SEARCH_LIST, payload: response.data.data});
      })
  }
}

export function searchById(value) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`, {params: {n: value}})
      .then((response) => {
        dispatch({type: CLIENTS_SEARCH_LIST, payload: response.data.data});
      })
  }
}

export function searchByIds(value) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`, {params: {find_by_ids: value.toString()}})
      .then((response) => {
        dispatch({type: CLIENTS_SEARCH_LIST, payload: response.data.data});
      })
  }
}