import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainLayout from "components/layout";
import { Tabs, DatePicker } from "antd";
import ReportByTrainers from './bytrainers'
import ReportByPlans from './byplans'
import { setMonth } from "actions/global"
import dayjs from 'dayjs';


const items = [
  {
    key: '0',
    label: `По тренерам`,
    children: <ReportByTrainers />,
  },
  {
    key: '1',
    label: `По форматам`,
    children: <ReportByPlans />,
  }
];

const ReportsTrainers = () => {

  const dispatch = useDispatch();

  useEffect(() => {
  }, []);

  const onChange = (value) => {
    dispatch(setMonth(value))
  }

  return (
    <MainLayout>
      <h1>Отчет по тренерам</h1>
      <b>Отчетный период:  </b>
      <DatePicker onChange={onChange} picker="month" bordered={false} style={{width: "200px"}} defaultValue={dayjs()} format={"MMMM YYYY"}/>
      <Tabs defaultActiveKey="0" items={items} />
    </MainLayout>
  );
}

export default ReportsTrainers;

