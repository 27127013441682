import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Typography, Row, Col, Card, Space } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { fetchDivisionsReport } from 'actions/reports'

const ReportByTrainers = () => {

  const dispatch = useDispatch();
  const month = useSelector(state => state.global.month)
  const division = useSelector(state => state.reports.division)

  useEffect(() => {
    dispatch(fetchDivisionsReport(month))
  }, [month]);

  return (
    <div>
      {division.map((i) => (
          <Card style={{marginBottom: 10}}>
            {/* <Typography.Title level={5} style={{margin:0}}><Typography.Text type="secondary">{i.name}</Typography.Text></Typography.Title> */}
          <Row>
            <Col span={24}>{Cities(i.cities)}</Col>
          </Row>
        </Card>
        ))}
    </div>
  )
}

const Cities = (c) => {

  return (
    c.map((i) => (
      <div>
          <Row>
            <Col span={5}><Typography.Title level={5} style={{margin:0}}>{i.name}</Typography.Title></Col>
          </Row>
          <Row>
            <Col span={5} offset={1}></Col>
            <Col span={4}>Прошлый месяц</Col>
            <Col span={4}>Этот месяц</Col>
          </Row> 
          <Row>
            <Col span={24} offset={1}>{Clubs(i.clubs)}</Col>
          </Row>
          </div>
      ))
  )
}

const Clubs = (c) => {

  return (
    c.map((i) => (
      <div>
          <Row>
            <Typography.Title level={5} style={{margin:"5px 0 5px 0", fontSize: 14}}>{i.name}</Typography.Title>
          </Row> 
          <Row>
            <Col span={24}>{Trainers(i.trainers)}</Col>
          </Row>
          </div>
      ))
  )
}

const Trainers = (c) => {

  return (
    c.map((i) => (
      <div>
        <Row>
          <Col span={5}><Typography.Text>{i.name + " " + i.lastname}</Typography.Text></Col>
          <Col span={4}>{i.prev}</Col>
          <Col span={4}>{i.this}</Col>
        </Row>
      </div>
      ))
  )
}


export default ReportByTrainers;