import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import configStore, { history } from "store";
import MessageProvider from "components/message_provider";
import "build.css";
import Main from "components/routing";
import axios from "axios";
import { Service } from "axios-middleware";
import AuthMiddleware from "middlewares/auth_middleware";
import TokenMiddleware from "middlewares/token_middleware";
import { Helmet } from "react-helmet";
import ruRu from "antd/locale/ru_RU";

export const store = configStore();
const container = document.getElementById("root");
const root = createRoot(container);

const service = new Service(axios);
service.register([
  new TokenMiddleware(store),
  new AuthMiddleware(store, history),
]);

root.render(
  <React.StrictMode>
    <ConfigProvider locale={ruRu}>
      <Provider store={store}>
        <HistoryRouter history={history}>
          <MessageProvider>
            <Helmet>
              <title>Фитнес технологии</title>
            </Helmet>
            <Main />
          </MessageProvider>
        </HistoryRouter>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);
