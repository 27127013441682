import { combineReducers } from 'redux';
import {
  CLIENTS_LIST,
  CLIENTS_ITEM,
  CLIENTS_ADD,
  CLIENTS_SEARCH_LIST
} from "constants/clients";

export function list(state = [], action) {
    switch (action.type) {
        case CLIENTS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case CLIENTS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case CLIENTS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export function searchlist(state = [], action) {
    switch (action.type) {
        case CLIENTS_SEARCH_LIST:
            return action.payload;
        default:
            return state;
    }
}

export const clientsReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
    searchlist: searchlist
});
