import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { DatePicker, Form, Button, Select } from "antd";
import { fetchList } from "actions/workouts";

const WorkoutsSearch = () => {
  const dispatch = useDispatch();
  const trainers = useSelector((state) => state.trainers.list);

  const onFinish = (values) => {
    let val = {
      ...values,
      from_date: values.period[0].format("DD.MM.YYYY"),
      to_date: values.period[1].format("DD.MM.YYYY"),
      expand: "clients,trainer.club,plan",
    };
    delete val.period;
    dispatch(fetchList(val));
  };

  return (
    <Form autoComplete="off" onFinish={onFinish} labelCol={{ span: 4 }}>
      <Form.Item name="trainer_id" label="Тренер">
        <Select
          showSearch
          placeholder="Выберите тренера"
          allowClear
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        >
          {trainers.map((item) => (
            <Select.Option
              label={item.lastname + " " + item.name + " " + item.phone}
              key={item.id}
              value={item.id}
            >
              {item.lastname + " " + item.name + " " + item.phone}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="period" label="Период">
        <DatePicker.RangePicker format={"DD.MM.YYYY"} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Найти
        </Button>
      </Form.Item>
      <br />
      <br />
    </Form>
  );
};

export default WorkoutsSearch;
