import React from 'react';
import { Card } from 'antd';

const Trainers = () => {
  
  return (
    <Card title="Тренеры" bordered={false}>
      Тренеры
    </Card>
  );
}

export default Trainers;
