import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { addItem } from "actions/clubs";

const ClubsAdd = (props) => {
  const dispatch = useDispatch();
  const cities = useSelector((state) => state.cities.list);
  const companies = useSelector((state) => state.companies.list);

  const onFinish = (values) => {
    dispatch(addItem(values));
  };

  return (
    <Card
      type="inner"
      title="Создать клуб"
      extra={
        <Link to="/clubs">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите название",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Адрес"
          name="address"
          rules={[
            {
              required: true,
              message: "Введите адрес",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Описание" name="description">
          <Input />
        </Form.Item>

        <Form.Item
          label="Город"
          name="city_id"
          rules={[
            {
              required: true,
              message: "Выберите город",
            },
          ]}
        >
          <Select>
            {cities.map((item, key) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Фирма"
          name="company_id"
          rules={[
            {
              required: true,
              message: "Выберите фирму",
            },
          ]}
        >
          <Select>
            {companies.map((item, key) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ClubsAdd;
