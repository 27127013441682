export const TRAINERS_LIST = "TRAINERS_LIST";
export const TRAINERS_ITEM = "TRAINERS_ITEM";
export const TRAINERS_ADD = "TRAINERS_ADD";

export const TRAINERS_WORKOUTS_LIST = "TRAINERS_WORKOUTS_LIST";
export const TRAINERS_BILLS_LIST = "TRAINERS_BILLS_LIST";

export const TRAINERS_MONTH = "TRAINERS_MONTH";

export const TRAINERS_STATISTIC = "TRAINERS_STATISTIC";
