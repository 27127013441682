import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useDispatch } from 'react-redux';
import MainLayout from "../layout/index";
import WorkoutsList from "./list";
import WorkoutsAdd from "./add";
import WorkoutsEdit from "./edit";
import { fetchList as fetchListTrainers } from "actions/trainers";
import { fetchList as fetchListPlans } from "actions/plans";


const Workouts = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchListTrainers())
    dispatch(fetchListPlans())
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Тренировки</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<WorkoutsList/>} />
        <Route exact path="/add" element={<WorkoutsAdd/>} />
        <Route exact path="/:id" element={<WorkoutsEdit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Workouts;

