import axios from 'axios';
import {
  BILLS_LIST,
  BILLS_ITEM,
  BILLS_ADD
} from "constants/bills";
import { API_URL } from 'config/conf';
import * as message from 'actions/messages';
import { history } from "store";

const API_SECTION = "bills";

export function fetchList(params) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`, {params: params})
      .then((response) => {
          dispatch({type: BILLS_LIST, payload: response.data.data});
      })
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`)
      .then((response) => {
        dispatch({type: BILLS_ITEM, payload: response.data.data});
      })
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item)
      .then((response) => {
        dispatch({type: BILLS_ADD, payload: response.data.data});
        dispatch(message.success("Продажа создана"))
        history.push("/bills")
      })
  }
}

export function updateItem(item) {
  return (dispatch) => {
    axios.patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({type: BILLS_ITEM, payload: response.data.data});
        dispatch(message.success("Продажа обновлена"))
      })
  }
}
