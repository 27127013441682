import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import TrainersEdit from "./edit";
import TrainersWorkouts from "./workouts";
import TrainersBills from "./bills";
import Statistics from "./statistics";
import TrainersPlans from "./plans";

const items = [
  {
    key: "edit",
    label: `Данные тренера`,
    children: <TrainersEdit />,
  },
  {
    key: "plans",
    label: "Планы тренировок",
    children: <TrainersPlans />,
  },
  {
    key: "workouts",
    label: `Тренировки`,
    children: <TrainersWorkouts />,
  },
  {
    key: "sales",
    label: `Продажи`,
    children: <TrainersBills />,
  },
  {
    key: "statistics",
    label: `Статистика`,
    children: <Statistics />,
  },
];

const Item = () => {
  const item = useSelector((state) => state.trainers.item);

  return (
    <div>
      <h1>Тренер {item.name + " " + item.lastname}</h1>
      {/* <b>Отчетный период: </b>
      <DatePicker
        onChange={onChange}
        picker="month"
        bordered={false}
        style={{ width: "200px" }}
        defaultValue={dayjs()}
        format={"MMMM YYYY"}
      /> */}
      <Tabs defaultActiveKey="edit" items={items} />
    </div>
  );
};

export default Item;
