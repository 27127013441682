import React from 'react';
import MainLayout from "../layout/index";

const Profile = () => {
  
  return (
    <MainLayout>
      Profile
    </MainLayout>
  );
}

export default Profile;
