import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input } from "antd";
import { addItem } from "actions/companies";

const Add = (props) => {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(addItem(values));
  };

  return (
    <Card
      type="inner"
      title="Создать подразделение"
      extra={
        <Button type="primary" className="add-btn">
          <Link to="/companies">Назад</Link>
        </Button>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите название подразделения",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="ИНН"
          name="inn"
          rules={[
            {
              required: true,
              message: "Введите ИНН",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default Add;
