import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet";
import MainLayout from "../layout/index";
import BillsList from "./list";
import BillsAdd from "./add";
import BillsEdit from "./edit";

const Bills = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Продажи тренеров</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<BillsList/>} />
        <Route exact path="/add" element={<BillsAdd/>} />
        <Route exact path="/:id" element={<BillsEdit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Bills;

