import axios from "axios";
import { WORKOUTS_LIST, WORKOUTS_ITEM, WORKOUTS_ADD } from "constants/workouts";
import { API_URL } from "config/conf";
import * as message from "actions/messages";
import { history } from "store";

const API_SECTION = "workouts";

export function fetchList(params) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}`, { params: params })
      .then((response) => {
        dispatch({ type: WORKOUTS_LIST, payload: response.data.data });
      });
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`).then((response) => {
      dispatch({ type: WORKOUTS_ITEM, payload: response.data.data });
    });
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item).then((response) => {
      dispatch({ type: WORKOUTS_ADD, payload: response.data.data });
      dispatch(message.success("Тренировка создана"));
      history.push("/workouts");
    });
  };
}

export function updateItem(item) {
  return (dispatch) => {
    axios
      .patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({ type: WORKOUTS_ITEM, payload: response.data.data });
        dispatch(message.success("Тренировка обновлена"));
      });
  };
}
