import React from 'react';
import { Layout, Row, Col } from 'antd';

const {Content} = Layout;

class AuthLayout extends React.Component {
  render() {
    return (
          <Content style={{maxWidth: 1600}}>
              <Row>
                <Col span={8} offset={8}>
                <div className="login-content">
                    {this.props.children}
                </div>
                </Col>
              </Row>
          </Content>
    );
  }
}

export default AuthLayout;
