import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input } from "antd";
import { addItem } from "actions/admins";

const FormItem = Form.Item;

const AdminsAdd = () => {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    const newval = { ...values, role: "admin" };
    dispatch(addItem(newval));
  };

  return (
    <Card
      type="inner"
      title="Создать администратора"
      extra={
        <Link to="/admins">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Имя"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите имя",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Введите фамилию",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Отчество"
          name="middlename"
          rules={[
            {
              required: true,
              message: "Введите отчество",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              message: "Введите телефон",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Почта"
          name="email"
          rules={[
            {
              type: "email",
              message: "Введите правильный адрес почты",
            },
            {
              required: true,
              message: "введите почту",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AdminsAdd;
