import axios from "axios";
import { CLUBS_LIST, CLUBS_ITEM, CLUBS_ADD } from "constants/clubs";
import { API_URL } from "../config/conf";
import * as message from "./messages";
import { history } from "store";

const API_SECTION = "clubs";

export function fetchList(params) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}`, { params: params })
      .then((response) => {
        dispatch({ type: CLUBS_LIST, payload: response.data.data });
      });
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`).then((response) => {
      dispatch({ type: CLUBS_ITEM, payload: response.data.data });
    });
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item).then((response) => {
      dispatch({ type: CLUBS_ADD, payload: response.data.data });
      dispatch(message.success("Клуб создан"));
      history.push("/clubs");
    });
  };
}

export function updateItem(item) {
  return (dispatch) => {
    axios
      .patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({ type: CLUBS_ITEM, payload: response.data.data });
        dispatch(message.success("Клуб обновлен"));
      });
  };
}
