import { combineReducers } from 'redux';
import { connectRouter } from '@superilya/connected-react-router-18';
import { globalReducers } from 'reducers/global'
import { loginReducers } from 'reducers/login'
import { citiesReducers } from 'reducers/cities'
import { clubsReducers } from 'reducers/clubs'
import { trainersReducers } from 'reducers/trainers'
import { plansReducers } from 'reducers/plans'
import { clientsReducers } from 'reducers/clients'
import { messagesReducers } from 'reducers/messages'
import { adminsReducers } from 'reducers/admins'
import { billsReducers } from 'reducers/bills'
import { workoutsReducers } from 'reducers/workouts'
import { acceptReducers } from 'reducers/accept'
import { companiesReducers } from 'reducers/companies';
import { reportsReducers } from 'reducers/reports';

export default (history) => combineReducers({
  router: connectRouter(history),
  global: globalReducers(),
  login: loginReducers(),
  cities: citiesReducers(),
  clubs: clubsReducers(),
  trainers: trainersReducers(),
  plans: plansReducers(),
  clients: clientsReducers(),
  messages: messagesReducers(),
  admins: adminsReducers(),
  bills: billsReducers(),
  workouts: workoutsReducers(),
  accept: acceptReducers(),
  companies: companiesReducers(),
  reports: reportsReducers()
});
