import axios from "axios";
import {
  PLANS_LIST,
  PLANS_ITEM,
  PLANS_ADD,
  PLANS_LIST_ACTIVE,
} from "constants/plans";
import { API_URL } from "config/conf";
import * as message from "actions/messages";
import { history } from "store";

const API_SECTION = "plans";

export function fetchList() {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`).then((response) => {
      dispatch({ type: PLANS_LIST, payload: response.data.data });
    });
    axios
      .get(`${API_URL}${API_SECTION}`, { params: { active: 1 } })
      .then((response) => {
        dispatch({ type: PLANS_LIST_ACTIVE, payload: response.data.data });
      });
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`).then((response) => {
      dispatch({ type: PLANS_ITEM, payload: response.data.data });
    });
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item).then((response) => {
      dispatch({ type: PLANS_ADD, payload: response.data.data });
      dispatch(message.success("План создан"));
      history.push("/plans");
    });
  };
}

export function updateItem(item) {
  return (dispatch) => {
    axios
      .patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({ type: PLANS_ITEM, payload: response.data.data });
        dispatch(message.success("План обновлен"));
      });
  };
}
