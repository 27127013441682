import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { history } from "store";
import { fetchList } from "actions/clubs";

const ClubsTable = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.clubs.list);

  useEffect(() => {
    dispatch(fetchList({ expand: "city,company" }));
  }, []);

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Адрес",
      dataIndex: "address",
      key: "address",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Фирма",
      dataIndex: "company",
      key: "company",
      render: (text) => <div>{text ? text.name : "-"}</div>,
    },
    {
      title: "Город",
      dataIndex: "city",
      key: "city",
      render: (text) => <div>{text ? text.name : "-"}</div>,
    },
  ];

  return (
    <div>
      <Table
        className={"data-table"}
        pagination={false}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={list}
        locale={{ emptyText: "Нет данных" }}
        onRow={({ id }) => ({
          onClick: () => {
            history.push(`/clubs/${id}`);
          },
        })}
      />
    </div>
  );
};

export default ClubsTable;
