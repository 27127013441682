import { combineReducers } from 'redux';
import {
  TRAINERS_LIST,
  TRAINERS_ITEM,
  TRAINERS_ADD,
  TRAINERS_WORKOUTS_LIST,
  TRAINERS_BILLS_LIST,
  TRAINERS_MONTH,
  TRAINERS_STATISTIC
} from "constants/trainers";
import dayjs from 'dayjs';

export function list(state = [], action) {
    switch (action.type) {
        case TRAINERS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case TRAINERS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case TRAINERS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export function workouts(state = [], action) {
    switch (action.type) {
        case TRAINERS_WORKOUTS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function bills(state = [], action) {
    switch (action.type) {
        case TRAINERS_BILLS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function month(state = [dayjs()], action) {
    switch (action.type) {
        case TRAINERS_MONTH:
            return action.payload;
        default:
            return state;
    }
}


export function statistic(state = [], action) {
    switch (action.type) {
        case TRAINERS_STATISTIC:
            return action.payload;
        default:
            return state;
    }
}

export const trainersReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
    workouts: workouts,
    bills: bills,
    month: month,
    statistic: statistic
});
