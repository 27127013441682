import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Button, Input } from "antd";
import { fetchItem, updateItem } from "actions/companies";

const DivisionsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const item = useSelector((state) => state.companies.item);

  useEffect(() => {
    dispatch(fetchItem(id));
    form.setFieldsValue(item);
  }, [item.id]);

  const onFinish = (values) => {
    let val = { ...values, id: item.id };
    dispatch(updateItem(val));
  };

  return (
    <Card
      type="inner"
      title="Изменить город"
      extra={
        <Link to="/companies">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите название подразделения",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="ИНН"
          name="inn"
          rules={[
            {
              required: true,
              message: "Введите тэг",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default DivisionsEdit;
