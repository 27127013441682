import React from 'react';
import { Layout, Row, Col } from 'antd';
import AppHeader from "./header";

const { Content } = Layout;

const MainLayout = (props) => {
    return (
      <Layout style={{maxWidth: 1600}}>
          <Layout>
          <AppHeader />
            <Content style={{marginTop: "80px", marginBottom:"80px"}}>
              <Row>
                  <Col span={18} offset={3}>
                  <div className="content">
                      {props.children}
                  </div>
                  </Col>
                </Row>
            </Content>
          </Layout>
      </Layout>
    );
  }

export default MainLayout;
