import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { addItem } from "actions/trainers";

const TrainersAdd = () => {
  const dispatch = useDispatch();
  const clubs = useSelector((state) => state.clubs.list);

  const onFinish = (values) => {
    const val = { ...values, role: "trainer" };
    dispatch(addItem(val));
  };

  return (
    <Card
      type="inner"
      title="Создать тренера"
      extra={
        <Link to="/trainers">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Имя"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите имя",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Введите фамилию",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Отчество"
          name="middlename"
          rules={[
            {
              required: true,
              message: "Введите отчество",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              message: "Введите телефон",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Почта" name="email">
          <Input />
        </Form.Item>

        <Form.Item
          label="Клуб"
          name="club_id"
          rules={[
            {
              required: true,
              message: "Выберите клуб",
            },
          ]}
        >
          <Select>
            {clubs.map((item, key) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default TrainersAdd;
