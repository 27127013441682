import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, Form, Button, Input, Select, Space, Switch } from "antd";
import { fetchItem, updateItem } from "actions/trainers";

const TrainersEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const item = useSelector((state) => state.trainers.item);
  const clubs = useSelector((state) => state.clubs.list);
  const plans = useSelector((state) => state.plans.active);

  useEffect(() => {
    dispatch(fetchItem(id, { expand: "plans" }));
  }, [id]);

  useEffect(() => {
    if (item && item.id) {
      form.setFieldsValue(item);
    }
  }, [item]);

  const onFinish = (values) => {
    let val = { ...values, id: item.id };
    dispatch(updateItem(val));
  };

  return (
    <Space
      direction="vertical"
      size="middle"
      style={{
        display: "flex",
      }}
    >
      <Card type="inner" title="Редактировать тренера">
        <Form
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          labelCol={{
            span: 3,
          }}
        >
          <Form.Item
            label="Имя"
            name="name"
            rules={[
              {
                required: true,
                message: "Введите имя",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Фамилия"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Введите фамилию",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Отчество"
            name="middlename"
            rules={[
              {
                required: true,
                message: "Введите отчество",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Телефон"
            name="phone"
            rules={[
              {
                required: true,
                message: "Введите телефон",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label="Почта" name="email">
            <Input />
          </Form.Item>

          <Form.Item
            label="Клуб"
            name="club_id"
            rules={[
              {
                required: true,
                message: "Выберите клуб",
              },
            ]}
          >
            <Select>
              {clubs.map((item, key) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Активно" name="active" valuePropName="checked">
            <Switch />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Space>
  );
};

export default TrainersEdit;
