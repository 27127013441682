export const CURRENT_USER = "CURRENT_USER";
export const CURRENT_USER_LOADING = "CURRENT_USER_LOADING";

export const NAME_FOR_LOGIN_AFTER_SIGN_UP = "NAME_FOR_LOGIN_AFTER_SIGN_UP";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const ERRORE = "ERRORE";

export const TOKEN = "TOKEN";

export const PHONE = 'PHONE';

export const CODE = "CODE";

export const UCALLER_ID = 'UCALLER_ID'
