import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { addItem } from "actions/cities";

const CitiesAdd = () => {
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.companies.list);

  const onFinish = (values) => {
    dispatch(addItem(values));
  };

  return (
    <Card
      type="inner"
      title="Создать город"
      extra={
        <Button type="primary" className="add-btn">
          <Link to="/cities">Назад</Link>
        </Button>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите название города",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CitiesAdd;
