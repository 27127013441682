import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet";
import MainLayout from "../layout/index";
import AdminsList from "./list";
import AdminsAdd from "./add";
import AdminsEdit from "./edit";

const Admins = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Администраторы</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<AdminsList/>} />
        <Route exact path="/add" element={<AdminsAdd/>} />
        <Route exact path="/:id" element={<AdminsEdit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Admins;

