import ClientsTable from "./table";

const ClientsList = () => {
  return (
    <div>
      <h1>Клиенты</h1>
      <ClientsTable />
    </div>
  );
}

export default ClientsList;