import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Space, Row, Col } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


const ClientsList = (i) => (
  <div>{i.name + " " + i.lastname + " " + i.phone}</div>
)

const Plan = (i) => (
  <div>{i.name}</div>
)

const WorkoutsList = (w) => {
  return (
    w.map((i) => (
      <Card key={i.id} bordered={false}>
        <Row gutter={16}>
          <Col span={6}><b>{dayjs(i.date).format("DD.MM.YY HH:ss")}</b></Col>
          <Col span={6}>{Plan(i.plan)}</Col>
          <Col span={4}>Количество: {i.count}</Col>
          <Col span={4}>Стоимость: {i.price} руб.</Col>
          <Col span={4}>Итого: {i.amount} руб.</Col>
        </Row>
        {ClientsList(i.client)} 
      </Card>
      ))
  )
}

const TrainersBills = () => {

  const list = useSelector(state => state.trainers.bills)

    return (
      <div>
        <Space direction="vertical" style={{display: 'flex'}}>
          {WorkoutsList(list)}
        </Space>
      </div>
    );

}

export default TrainersBills;