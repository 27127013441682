import { combineReducers } from 'redux';
import {
  PLANS_LIST,
  PLANS_ITEM,
  PLANS_ADD,
  PLANS_LIST_ACTIVE
} from "constants/plans";

export function list(state = [], action) {
    switch (action.type) {
        case PLANS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case PLANS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case PLANS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export function active(state = [], action) {
    switch (action.type) {
        case PLANS_LIST_ACTIVE:
            return action.payload;
        default:
            return state;
    }
}

export const plansReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
    active: active
});
