import axios from 'axios';
import {
  GLOBAL_MONTH,
  MENU_CURRENT
} from "constants/global";
import { API_URL } from 'config/conf';
import * as message from 'actions/messages';
import { history } from "store";

export function setMonth(month) {
  return (dispatch) => {
    dispatch({type: GLOBAL_MONTH, payload: month.format("DD.MM.YYYY")});
  }
}

export function set_current_menu(key) { return { type: MENU_CURRENT, payload: key};}