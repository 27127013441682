import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { history } from "store";
import { fetchList } from "actions/companies";

const DivisionsTable = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.companies.list);

  useEffect(() => {
    dispatch(fetchList());
  }, []);

  const columns = [
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "ИНН",
      dataIndex: "inn",
      key: "inn",
      render: (text) => <div>{text}</div>,
    },
  ];

  return (
    <div>
      <Table
        className={"data-table"}
        pagination={false}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={list}
        locale={{ emptyText: "Нет данных" }}
        onRow={({ id }) => ({
          onClick: () => {
            history.push(`/companies/${id}`);
          },
        })}
      />
    </div>
  );
};

export default DivisionsTable;
