import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import MainLayout from "../layout/index";
import ClubsList from "./list";
import ClubsAdd from "./add";
import ClubsEdit from "./edit";
import { fetchList } from "actions/cities";
import { fetchList as fetchCompanies } from "actions/companies";

const Clubs = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchList());
    dispatch(fetchCompanies());
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Клубы</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<ClubsList />} />
        <Route exact path="/add" element={<ClubsAdd />} />
        <Route exact path="/:id" element={<ClubsEdit />} />
      </Routes>
    </MainLayout>
  );
};

export default Clubs;
