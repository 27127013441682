import axios from 'axios';
import {
  CITIES_LIST,
  CITIES_ITEM,
  CITIES_ADD
} from "../constants/cities";
import { API_URL } from '../config/conf';
import * as message from './messages';
import { history } from "store";

const API_SECTION = "cities";

export function fetchList() {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`)
      .then((response) => {
          dispatch({type: CITIES_LIST, payload: response.data.data});
      })
  };
}

export function fetchItem(id) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}/${id}`)
      .then((response) => {
        dispatch({type: CITIES_ITEM, payload: response.data.data});
      })
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item)
      .then((response) => {
        dispatch({type: CITIES_ADD, payload: response.data.data});
        dispatch(message.success("Город создан"))
        history.push("/cities")
      })
  }
}

export function updateItem(item) {
  return (dispatch) => {
    axios.patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({type: CITIES_ITEM, payload: response.data.data});
        dispatch(message.success("Город обновлен"))
      })
  }
}
