import React from 'react';
import DocumentTitle from 'react-document-title';
import { Button, Result } from 'antd';
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
      <DocumentTitle title="Страница не найдена">
        <Result
          status="404"
          title="404"
          style={{marginTop: 50}}
          subTitle="Извините страница не найдена"
          extra={<Link to="/"><Button type="primary">Перейти на главную</Button></Link>}
        />
      </DocumentTitle>
  )
}

export default PageNotFound;