import {Link} from "react-router-dom";
import {Row, Col, Button} from "antd";
import ClubsTable from "./table";

const ClubsList = () => {
    return (
        <div>
          <h1>Клубы</h1>
          <Row >
            <Col span={24}>
              <Link to="/clubs/add"><Button type="primary" className="add-btn">Создать</Button></Link>
            </Col>
          </Row>
          <ClubsTable />
        </div>
    );

}

export default ClubsList;