import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { fetchItem, updateItem } from "actions/clubs";

const ClubsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const item = useSelector(state => state.clubs.item)
  const cities = useSelector(state => state.cities.list)

  useEffect(() => {
    dispatch(fetchItem(id))
    form.setFieldsValue(item)
  }, [item.id]);

  const onFinish = (values) => {
    let val = {...values, id: item.id}
    dispatch(updateItem(val))
  };

  return (
    <Card type="inner" title="Редактировать клуб" extra={<Link to="/clubs"><Button type="primary" className="add-btn">Назад</Button></Link>}>
    <Form
    onFinish={onFinish}
    autoComplete="off"
    form={form}
    labelCol={{
      span: 4,
    }}
  >
<Form.Item
  label="Название"
  name="name"
  rules={[
    {
      required: true,
      message: 'Введите название',
    },
  ]}
>
  <Input />
</Form.Item>
<Form.Item
  label="Адрес"
  name="address"
  rules={[
    {
      required: true,
      message: 'Введите адрес',
    },
  ]}
>
  <Input />
</Form.Item>
<Form.Item
  label="Описание"
  name="description"
>
  <Input />
</Form.Item>

<Form.Item 
  label="Город"
  name="city_id"
  rules={[
    {
      required: true,
      message: 'Выберите город',
    }]}
>
    <Select>
      {cities.map((item, key) => (<Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>))}
    </Select>
  </Form.Item>

<Form.Item>
  <Button type="primary" htmlType="submit">
    Сохранить
  </Button>
</Form.Item>
</Form>

  </Card>
);
}

export default ClubsEdit;
