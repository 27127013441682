import { combineReducers } from 'redux';
import {
  COMPANIES_LIST,
  COMPANIES_ITEM,
  COMPANIES_ADD
} from "constants/companies";

export function list(state = [], action) {
    switch (action.type) {
        case COMPANIES_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case COMPANIES_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case COMPANIES_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const companiesReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
