import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Badge, Card, Space, Button, Typography, Drawer } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import WorkoutsSearch from "./search";
import { fetchList } from "actions/workouts";

const WorkoutsTable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const list = useSelector((state) => state.workouts.list);

  useEffect(() => {
    dispatch(fetchList({ expand: "clients,trainer.club,plan" }));
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Space>
        <Link to="/workouts/add">
          <Button type="primary" className="add-btn">
            Создать
          </Button>
        </Link>
        <Button onClick={showDrawer} type="primary" icon={<SearchOutlined />}>
          Поиск
        </Button>
        <Drawer
          title="Поиск"
          placement="right"
          onClose={onClose}
          open={open}
          size="large"
        >
          <WorkoutsSearch />
        </Drawer>
      </Space>
      {WorkoutsList(list)}
    </Space>
  );
};

export default WorkoutsTable;

const ClientsList = (c) => {
  return c.map((i) => (
    <div key={i.id}>{i.name + " " + i.lastname + " " + i.phone}</div>
  ));
};

const Status = (status) => {
  if (status === "recent") {
    return "В ожидании";
  }
  if (status === "canceled") {
    return "Отмененная";
  }
  if (status === "success") {
    return "Успешная";
  }
};

const Color = (status) => {
  if (status === "recent") {
    return "orange";
  }
  if (status === "canceled") {
    return "red";
  }
  if (status === "success") {
    return "green";
  }
};

const WorkoutsList = (w) => {
  return w.map((i) => (
    <Link to={"/workouts/" + i.id}>
      <Badge.Ribbon text={Status(i.status)} color={Color(i.status)} key={i.id}>
        <Card bordered={false}>
          <Space size="large">
            <Typography.Text strong>
              {dayjs(i.date).format("DD.MM.YY HH:ss")}
            </Typography.Text>
            <Typography.Text type="secondary">
              {i.trainer.club.name}
            </Typography.Text>
            <Typography.Text type="secondary">{i.plan.name}</Typography.Text>
          </Space>
          {ClientsList(i.clients)}
        </Card>
      </Badge.Ribbon>
    </Link>
  ));
};
