import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { isLoggedIn } from "actions/login";

const PrivateRoute = () => {
  return isLoggedIn() ? <Outlet /> : <Navigate to="/login" />;
  return <Outlet />;
};

export default PrivateRoute;
