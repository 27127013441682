import axios from "axios";
import {
  TRAINERS_LIST,
  TRAINERS_ITEM,
  TRAINERS_ADD,
  TRAINERS_WORKOUTS_LIST,
  TRAINERS_BILLS_LIST,
  TRAINERS_MONTH,
  TRAINERS_STATISTIC,
} from "constants/trainers";
import { API_URL } from "config/conf";
import * as message from "actions/messages";
import { history } from "store";

const API_SECTION = "users";

export function fetchList(params) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}`, { params: params })
      .then((response) => {
        dispatch({ type: TRAINERS_LIST, payload: response.data.data });
      });
  };
}

export function fetchItem(id, params) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}/${id}`, { params: params })
      .then((response) => {
        dispatch({ type: TRAINERS_ITEM, payload: response.data.data });
      });
  };
}

export function addItem(item) {
  return (dispatch) => {
    axios.post(`${API_URL}${API_SECTION}`, item).then((response) => {
      dispatch({ type: TRAINERS_ADD, payload: response.data.data });
      dispatch(message.success("Тренер создан"));
      history.push("/trainers");
    });
  };
}

export function updateItem(item) {
  return (dispatch) => {
    axios
      .patch(`${API_URL}${API_SECTION}/${item.id}`, item)
      .then((response) => {
        dispatch({ type: TRAINERS_ITEM, payload: response.data.data });
        dispatch(message.success("Тренер обновлен"));
      });
  };
}

export function fetchTrainersWorkouts(id, month) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}/${id}/workouts`, {
        params: { month: month },
      })
      .then((response) => {
        dispatch({ type: TRAINERS_WORKOUTS_LIST, payload: response.data.data });
      });
  };
}

export function fetchTrainersBills(id, month) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}/${id}/bills`, { params: { month: month } })
      .then((response) => {
        dispatch({ type: TRAINERS_BILLS_LIST, payload: response.data.data });
      });
  };
}

export function setMonth(id, month) {
  return (dispatch) => {
    dispatch({ type: TRAINERS_MONTH, payload: month.format("DD.MM.YYYY") });
    // dispatch(fetchTrainersWorkouts(id, month.format("DD.MM.YYYY")));
    // dispatch(fetchTrainersBills(id, month.format("DD.MM.YYYY")));
    // dispatch(fetchTrainersStatistic(id, month.format("DD.MM.YYYY")));
  };
}

export function fetchTrainersStatistic(id, month) {
  return (dispatch) => {
    axios
      .get(`${API_URL}${API_SECTION}/${id}/statistics`, {
        params: { month: month },
      })
      .then((response) => {
        dispatch({ type: TRAINERS_STATISTIC, payload: response.data });
      });
  };
}

export function addTrainerPlan(id, item) {
  return (dispatch) => {
    axios
      .post(`${API_URL}${API_SECTION}/${id}/user_plans`, item)
      .then((response) => {
        dispatch({ type: TRAINERS_ITEM, payload: response.data.data });
        dispatch(message.success("План обновлен"));
      });
  };
}
