import { getToken } from 'actions/login'

export default class TokenMiddleware {

  onRequest(config) {
    return {
      ...config,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${getToken()}`,
        ...config.headers
      }
    };
  }
}
