import axios from "axios";
import Cookie from "js-cookie";
import { API_URL } from "../config/conf";
import * as message from "./messages";
import { history } from "store";
import { PHONE, UCALLER_ID } from "constants/login";

export function login(params) {
  return (dispatch) => {
    axios.post(`${API_URL}auth/send_code`, params).then((response) => {
      dispatch({ type: PHONE, payload: response.data.phone });
      dispatch({ type: UCALLER_ID, payload: response.data.phone });
      history.push("/code");
    });
  };
}

export function chechcode(params) {
  return (dispatch) => {
    axios.post(`${API_URL}auth/signin_admin`, params).then((response) => {
      setToken(response.data.meta.access_token);
      dispatch(message.success("Добро пожаловать"));
      history.push("/");
    });
  };
}

export function repeatcall(ucallerid) {
  return (dispatch) => {
    axios.post(`${API_URL}auth/repeatcall`, { id: ucallerid }).then(() => {
      dispatch(message.success("Новый звонок запрошен"));
    });
  };
}

export function isLoggedIn() {
  if (getToken()) {
    return true;
  } else {
    history.push("/login");
    return false;
  }
}

export function setToken(token) {
  Cookie.set("token", token);
}

export function logout() {
  Cookie.remove("token");
  history.push("/login");
}

export function getToken() {
  return Cookie.get("token") || null;
}

export function setTokenHeaders() {
  const token = this.getToken();
  if (token) {
    return {
      headers: {
        authorization: `JWT ${token}`,
      },
    };
  }
}
