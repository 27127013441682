import { combineReducers } from 'redux';
import {
  SUCCESS_MESSAGE,
  ERRORE_MESSAGE,
  LOADING_MESSAGE
} from "constants/messages";

export function success(state = null, action) {
  switch (action.type) {
    case SUCCESS_MESSAGE:
      return action.message;
    default:
      return state;
  }
}

export function error(state = null, action) {
  switch (action.type) {
    case ERRORE_MESSAGE:
      return action.message;
    default:
      return state;
  }
}

export function loading(state = false, action) {
  switch (action.type) {
    case LOADING_MESSAGE:
      return action.bool;
    default:
      return state;
  }
}

export const messagesReducers = () => combineReducers({
  success: success,
  error: error,
  loading: loading
});
