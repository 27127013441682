import { combineReducers } from 'redux';
import {
  CLUBS_LIST,
  CLUBS_ITEM,
  CLUBS_ADD
} from "constants/clubs";

export function list(state = [], action) {
    switch (action.type) {
        case CLUBS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case CLUBS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case CLUBS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const clubsReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
