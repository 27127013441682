import React  from "react";
import { useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { addItem } from "actions/clients";

const ClientsAdd = () => {
  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(addItem(values))
  }

  return (
        <Card type="inner" title="Создать клиента" extra={<Link to="/clients"><Button type="primary" className="add-btn">Назад</Button></Link>}>
        <Form
        onFinish={onFinish}
        autoComplete="off"
        disabled="true"
        labelCol={{
          span: 4,
        }}
      >
    <Form.Item
      label="Имя"
      name="name"
      rules={[
        {
          required: true,
          message: 'Введите имя',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Фамилия"
      name="lastname"
      rules={[
        {
          required: true,
          message: 'Введите фамилию',
        },
      ]}
    >
      <Input />
    </Form.Item>
    <Form.Item
      label="Отчество"
      name="middlename"
      rules={[
        {
          required: true,
          message: 'Введите отчество',
        }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Телефон"
      name="phone" 
      rules={[
        {
          required: true,
          message: 'Введите телефон',
        }]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Почта"
      name="email" 
    >
      <Input />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit">
        Создать
      </Button>
    </Form.Item>
  </Form>

      </Card>
  );
}

export default ClientsAdd;
