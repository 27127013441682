import axios from 'axios';
import {
  ACCEPT_LOADING,
  ACCEPT_ITEM
} from "constants/accept";
import { API_URL_ACCEPTANCE } from 'config/conf';

const API_SECTION = "acceptance";

export function findLink(id) {
  return (dispatch) => {
    axios.post(`${API_URL_ACCEPTANCE}${API_SECTION}`, {link: id})
      .then((response) => {
          dispatch({type: ACCEPT_ITEM, payload: response.data.data});
          dispatch({type: ACCEPT_LOADING, payload: false});
      })
  };
}
