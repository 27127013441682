import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Button, Input, Switch } from "antd";
import { fetchItem, updateItem } from "actions/admins";

const AdminsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const item = useSelector((state) => state.admins.item);

  useEffect(() => {
    dispatch(fetchItem(id));
  }, [id]);

  useEffect(() => {
    if (item && item.id) {
      form.setFieldsValue(item);
    }
  }, [item]);

  const onFinish = (values) => {
    let val = { ...values, id: item.id };
    dispatch(updateItem(val));
  };

  return (
    <Card
      type="inner"
      title="Изменить администратора"
      extra={
        <Link to="/admins">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Имя"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите имя",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Введите фамилию",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Отчество"
          name="middlename"
          rules={[
            {
              required: true,
              message: "Введите отчество",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              message: "Введите телефон",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Почта"
          name="email"
          rules={[
            {
              type: "email",
              message: "Введите правильный адрес почты",
            },
            {
              required: true,
              message: "введите почту",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Активно" name="active" valuePropName="checked">
          <Switch />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default AdminsEdit;
