import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Result, Button } from 'antd';
import { findLink } from "actions/accept";


const Acceptlink = () => {

  const dispatch = useDispatch();
  const { id } = useParams();
  const loading = useSelector(state => state.accept.loading)
  const item = useSelector(state => state.accept.item)

  useEffect(() => {
    dispatch(findLink(id))
  }, []);

  if(loading) {
    return (<Spin style={{position: "absolute", top:" 50%", left: "50%" }}></Spin>)
  }

  if(item) {
    const text = "Вы успешно акцептовали договор " + item.id + " с тренером " + item.trainer.lastname + " " + item.trainer.name + "." + " На сумму: " + item.amount + " руб."
    return (
      <Result
        style={{marginTop: 100}}
        status="success"
        title="Вы успешно акцептовали договор"
        subTitle={text}
        extra={[
          <Button type="primary" key="console" onClick={() => window.close()}>
            Закрыть страницу
          </Button>
        ]}
      />
  )}

}

export default Acceptlink;