import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "antd";
import { history } from "store";
import { fetchList } from "actions/trainers";

const TrainersTable = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.trainers.list);

  useEffect(() => {
    dispatch(fetchList({ role: "trainer" }));
  }, []);

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Фамилия",
      dataIndex: "lastname",
      key: "lastname",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Отчество",
      dataIndex: "middlename",
      key: "middlename",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Клуб",
      dataIndex: "club",
      key: "club",
      render: (text) => <div>{text ? text.name : "-"}</div>,
    },
  ];

  return (
    <div>
      <Table
        className={"data-table"}
        pagination={false}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={list}
        locale={{ emptyText: "Нет данных" }}
        onRow={({ id }) => ({
          onClick: () => {
            history.push(`/trainers/${id}`);
          },
        })}
      />
    </div>
  );
};

export default TrainersTable;
