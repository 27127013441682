import React, { useEffect } from 'react';
import { logout } from 'actions/login';


const LogoutComponent = () => {

  useEffect(() => {
    logout()
  });

}
  
export default LogoutComponent;
