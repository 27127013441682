import BillsTable from "./table";

const BillsList = () => {
    return (
        <div>
          <h1>Продажи тренеров</h1>
          <BillsTable />
        </div>
    );

}

export default BillsList;