import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Typography } from "antd";
import { history } from "store";
import { fetchList } from "actions/plans";

const { Text } = Typography;

const PlansTable = () => {
  const dispatch = useDispatch();
  const list = useSelector(state => state.plans.list)
  

  useEffect(() => {
    dispatch(fetchList())
  }, []);

  const columns = [
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (_, record) => ( record.active == false ? <Text disabled>{record.name}</Text> : record.name)
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => ( record.active == false ? <Text disabled>{record.description}</Text> : record.description)
    }
  ];


  return (
    <div>
      <Table
        className={'data-table'}
        pagination={false}
        bordered
        rowKey='id'
        columns={columns}
        dataSource={list}
        locale={{'emptyText': 'Нет данных'}}
        onRow={({id}) => ({
          onClick: () => {
            history.push(`/plans/${id}`);
          }
        })}
      />
    </div>
  )
}

export default PlansTable;