import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {Helmet} from "react-helmet";
import MainLayout from "../layout/index";
import CitiesList from "./list";
import CitiesAdd from "./add";
import CitiesEdit from "./edit";
import { fetchList } from 'actions/companies';

const Cities = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchList())
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Города</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<CitiesList/>} />
        <Route exact path="/add" element={<CitiesAdd/>} />
        <Route exact path="/:id" element={<CitiesEdit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Cities;

