import React, { useEffect } from 'react';
import {Helmet} from "react-helmet";
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MainLayout from "../layout/index";
import PlansList from "./list";
import PlansAdd from "./add";
import PlansEdit from "./edit";
import { fetchList } from "actions/clubs";

const Plans = () => {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchList())
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Форматы тренировок</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<PlansList/>} />
        <Route exact path="/add" element={<PlansAdd/>} />
        <Route exact path="/:id" element={<PlansEdit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Plans;

