import React from 'react';
import {Helmet} from "react-helmet";
import { Route, Routes } from 'react-router-dom';
import MainLayout from "../layout/index";
import ClientsList from "./list";
import ClientsAdd from "./add";
import ClientsEdit from "./edit";

const Clients = () => {

  return (
    <MainLayout>
      <Helmet>
        <title>Клиенты</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<ClientsList/>} />
        <Route exact path="/add" element={<ClientsAdd/>} />
        <Route exact path="/:id" element={<ClientsEdit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Clients;

