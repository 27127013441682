import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Space, Statistic } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

const ReportByCities = () => {

  return (     
    <div>Отчет по форматам тренировки</div>
  );
}

export default ReportByCities;