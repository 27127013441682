import {Link} from "react-router-dom";
import {Row, Col, Button} from "antd";
import CitiesTable from "./table";

const CitiesList = () => {
    return (
        <div>
          <h1>Города</h1>
          <Row >
            <Col span={24}>
              <Link to="/cities/add"><Button type="primary" className="add-btn">Создать</Button></Link>
            </Col>
          </Row>
          <CitiesTable />
        </div>
    );

}

export default CitiesList;