import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, DatePicker, Select, Radio } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { addItem } from "actions/workouts";
import { search } from "actions/clients";

const WorkoutsAdd = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const trainers = useSelector((state) => state.trainers.list);
  const plans = useSelector((state) => state.plans.list);
  const clients = useSelector((state) => state.clients.searchlist);

  useEffect(() => {
    form.setFieldsValue({ date: dayjs(new Date()).locale("ru") });
  }, []);

  const onFinish = (values) => {
    dispatch(addItem(values));
  };

  const handleSearchClient = (value) => {
    if (value.length > 3) {
      dispatch(search(value));
    }
  };

  return (
    <Card
      type="inner"
      title="Создать тренировку"
      extra={
        <Link to="/workouts">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Дата"
          name="date"
          rules={[
            {
              required: true,
              message: "Введите дату",
            },
          ]}
        >
          <DatePicker placeholder="Дата" format={"DD.MM.YYYY HH:ss"} showTime />
        </Form.Item>

        <Form.Item
          label="Тренер"
          name="trainer_id"
          rules={[
            {
              required: true,
              message: "Введите тренера",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Выберите тренера"
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          >
            {trainers.map((item) => (
              <Select.Option
                label={item.lastname + " " + item.name + " " + item.phone}
                key={item.id}
                value={item.id}
              >
                {item.lastname + " " + item.name + " " + item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Клиент"
          name="clients_ids"
          rules={[
            {
              required: true,
              message: "Введите клиента",
            },
          ]}
        >
          <Select
            showSearch
            mode="multiple"
            placeholder="Выберите клиента"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onSearch={handleSearchClient}
          >
            {clients.map((item) => (
              <Select.Option
                label={
                  item.lastname +
                  " " +
                  item.name +
                  " " +
                  item.middlename +
                  " " +
                  item.phone
                }
                key={item.id}
                value={item.id}
              >
                {item.lastname +
                  " " +
                  item.name +
                  " " +
                  item.middlename +
                  " " +
                  item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Формат тренировки"
          name="plan_id"
          rules={[
            {
              required: true,
              message: "Введите план",
            },
          ]}
        >
          <Select placeholder="Выберите формат тренировки">
            {plans.map((item) => (
              <Select.Option label={item.name} key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Описание тренировки" name="description">
          <Input placeholder="Комментарии к тренировке. Видит только тренер" />
        </Form.Item>

        <Form.Item label="Статус" name="status">
          <Radio.Group buttonStyle="solid">
            <Radio.Button value="recent">В ожидании</Radio.Button>
            <Radio.Button value="success">Успешная</Radio.Button>
            <Radio.Button value="canceled">Отмененная</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default WorkoutsAdd;
