import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, DatePicker, Select } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { addItem } from "actions/bills";
import { search } from "actions/clients";

const BillsAdd = () => {
  const dispatch = useDispatch();

  const trainers = useSelector((state) => state.trainers.list);
  const plans = useSelector((state) => state.plans.list);
  const clients = useSelector((state) => state.clients.searchlist);

  const onFinish = (values) => {
    dispatch(addItem(values));
  };

  const handleSearchClient = (value) => {
    if (value.length > 3) {
      dispatch(search(value));
    }
  };

  return (
    <Card
      type="inner"
      title="Создать продажу"
      extra={
        <Link to="/bills">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Дата"
          name="date"
          rules={[
            {
              required: true,
              message: "Введите дату",
            },
          ]}
        >
          <DatePicker placeholder="Дата" format={"DD.MM.YYYY"} />
        </Form.Item>

        <Form.Item
          label="Тренер"
          name="trainer_id"
          rules={[
            {
              required: true,
              message: "Введите тренера",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Выберите тренера"
            allowClear
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
          >
            {trainers.map((item) => (
              <Select.Option
                label={item.lastname + " " + item.name + " " + item.phone}
                key={item.id}
                value={item.id}
              >
                {item.lastname + " " + item.name + " " + item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Клиент"
          name="client_id"
          rules={[
            {
              required: true,
              message: "Введите клиента",
            },
          ]}
        >
          <Select
            showSearch
            allowClear
            placeholder="Выберите клиента"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            onSearch={handleSearchClient}
          >
            {clients.map((item) => (
              <Select.Option
                label={item.lastname + " " + item.name + " " + item.phone}
                key={item.id}
                value={item.id}
              >
                {item.lastname + " " + item.name + " " + item.phone}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Формат тренировки"
          name="plan_id"
          rules={[
            {
              required: true,
              message: "Введите план",
            },
          ]}
        >
          <Select placeholder="Выберите формат тренировки">
            {plans.map((item) => (
              <Select.Option label={item.name} key={item.id} value={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Количество"
          name="count"
          rules={[
            {
              required: true,
              message: "Введите количество",
            },
          ]}
        >
          <Input placeholder="Введите количество проданых тренировок" />
        </Form.Item>

        <Form.Item label="Цена" name="price">
          <Input placeholder="Введите цену тренировки при необходимости" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Создать
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default BillsAdd;
