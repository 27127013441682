import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Card, Space, Button, Typography, Drawer } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import ClientsSearch from "./search";
import { fetchList } from "actions/clients";

const ClientsTable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const list = useSelector((state) => state.clients.list);

  useEffect(() => {
    dispatch(fetchList());
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Space>
        <Link to="/clients/add">
          <Button type="primary" className="add-btn">
            Создать
          </Button>
        </Link>
        <Button onClick={showDrawer} type="primary" icon={<SearchOutlined />}>
          Поиск
        </Button>
        <Drawer
          title="Поиск"
          placement="right"
          onClose={onClose}
          open={open}
          size="large"
        >
          <ClientsSearch />
        </Drawer>
      </Space>
      {ClientsList(list)}
    </Space>
  );
};

export default ClientsTable;

const ClientsList = (w) => {
  return w.map((i) => (
    <Link to={"/clients/" + i.id}>
      <Card bordered={false}>
        <Row>
          <Typography.Text strong>
            {i.lastname + " " + i.name + " " + i.middlename}
          </Typography.Text>
        </Row>
        <Row>
          <Space size="large">
            <Typography.Text type="secondary">{i.phone}</Typography.Text>
            <Typography.Text type="secondary">{i.email}</Typography.Text>
          </Space>
        </Row>
      </Card>
    </Link>
  ));
};
