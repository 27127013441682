import { combineReducers } from 'redux';
import {
  CURRENT_USER,
  CODE,
  PHONE,
  UCALLER_ID
} from "../constants/login";

export function current_user(state = null, action) {
  switch (action.type) {
    case CURRENT_USER:
      return action.user;
    default:
      return state;
  }
}

export function phone(state = [], action) {
  switch (action.type) {
    case PHONE:
      return action.payload;
    default:
      return state;
  }
}

export function code(state = [], action) {
  switch (action.type) {
    case CODE:
      return action.payload;
    default:
      return state;
  }
}

export function ucallerid(state = [], action) {
  switch (action.type) {
    case UCALLER_ID:
      return action.payload;
    default:
      return state;
  }
}

export const loginReducers = () => combineReducers({
  current_user: current_user,
  code: code,
  phone: phone,
  ucallerid: ucallerid
});
