import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { Badge, Card, Space, Button, Typography, Drawer } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import WorkoutsSearch from "./search";
import { fetchList } from "actions/bills";

const BillsTable = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const list = useSelector((state) => state.bills.list);

  useEffect(() => {
    dispatch(fetchList());
  }, []);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Space>
        <Link to="/bills/add">
          <Button type="primary" className="add-btn">
            Создать
          </Button>
        </Link>
        <Button onClick={showDrawer} type="primary" icon={<SearchOutlined />}>
          Поиск
        </Button>
        <Drawer
          title="Поиск"
          placement="right"
          onClose={onClose}
          open={open}
          size="large"
        >
          <WorkoutsSearch />
        </Drawer>
      </Space>
      {BillsList(list)}
    </Space>
  );
};

export default BillsTable;

const Client = (i) => (
  <div key={i.id}>{i.name + " " + i.lastname + " " + i.phone}</div>
);
const Trainer = (i) => (
  <div key={i.id}>{i.name + " " + i.lastname + " " + i.phone}</div>
);

const Status = (i) => {
  if (i) {
    return "Акцепт подписан клиентом";
  } else {
    return "Акцепт не подписан клиентом";
  }
};

const Color = (i) => {
  if (i) {
    return "green";
  } else {
    return "red";
  }
};

const BillsList = (w) => {
  return w.map((i) => (
    <Link to={"/bills/" + i.id}>
      <Badge.Ribbon text={Status(i.acceptance)} color={Color(i.acceptance)}>
        <Card bordered={false} key={i.id}>
          <Space size="large">
            <Typography.Text strong>
              {dayjs(i.date).format("DD.MM.YY HH:ss")}
            </Typography.Text>
            <Typography.Text type="secondary">{i.plan.name}</Typography.Text>
            <Typography.Text type="secondary">
              {Trainer(i.trainer)}
            </Typography.Text>
          </Space>
          {Client(i.client)}
          <Space size="large">
            <Typography.Text type="secondary">
              Количество: {i.count}
            </Typography.Text>
            <Typography.Text type="secondary">
              Цена: {i.price} руб.
            </Typography.Text>
            <Typography.Text type="secondary">
              Стоимость: {i.amount} руб.
            </Typography.Text>
          </Space>
        </Card>
      </Badge.Ribbon>
    </Link>
  ));
};
