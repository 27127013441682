import WorkoutsTable from "./table";

const WorkoutsList = () => {
    return (
        <div>
          <h1>Тренировки</h1>
          <WorkoutsTable />
        </div>
    );

}

export default WorkoutsList;