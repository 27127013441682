import React from 'react';
import { Helmet } from "react-helmet";
import MainLayout from "../layout/index";
import Grid from './grid';

const Dashboard = () => {
  
  return (
    <MainLayout>
      <Helmet>
        <title>Дашборд</title>
      </Helmet>
      <h1>Главная</h1>
      <br></br>
      <Grid />
    </MainLayout>
  );
}

export default Dashboard;
