import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Select, Form, Input } from 'antd';
import AuthLayout from "../layout/auth_layout";
import { login } from "actions/login"

const LoginComponent = () => {

  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(login(values))
  }

  return (
  <AuthLayout>
  <Form
    name="basic"
    labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    autoComplete="on"
  >
    <h2>Вход</h2>
    <div className="form__note form__note_centered">Введите номер телефона для входа. Авторизация в приложении будет действовать 10 дней.</div>
    <Form.Item
      label="Телефон"
      name="phone"
      rules={[
        {
          required: true,
          message: 'Введите номер телефона',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Отправить код
      </Button>
    </Form.Item>
  </Form>
  </AuthLayout>
)};

export default LoginComponent;