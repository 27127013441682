import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Button, Input, Switch } from "antd";
import { fetchItem, updateItem } from "actions/plans";

const PlansEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const item = useSelector(state => state.plans.item)
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(fetchItem(id))
    form.setFieldsValue(item)
  }, [item.id]);

  const onFinish = (values) => {
    let val = {...values, id: item.id}
    dispatch(updateItem(val))
  };

  return (
    <Card type="inner" title="Редактировать формат тренировки" extra={<Link to="/plans"><Button type="primary" className="add-btn">Назад</Button></Link>}>
    <Form
    onFinish={onFinish}
    autoComplete="off"
    form={form}
    labelCol={{
      span: 4,
    }}
  >
    <Form.Item
      label="Имя"
      name="name"
      rules={[
        {
          required: true,
          message: 'Введите имя',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Описание"
      name="description" 
    >
      <Input.TextArea />
    </Form.Item>
    
    <Form.Item label="Активно" name="active"  valuePropName="checked">
        <Switch />
      </Form.Item>

<Form.Item>
  <Button type="primary" htmlType="submit">
    Сохранить
  </Button>
</Form.Item>
</Form>

  </Card>
);
}

export default PlansEdit;
