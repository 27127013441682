import { combineReducers } from 'redux';
import {
  WORKOUTS_LIST,
  WORKOUTS_ITEM,
  WORKOUTS_ADD
} from "constants/workouts";

export function list(state = [], action) {
    switch (action.type) {
        case WORKOUTS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case WORKOUTS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case WORKOUTS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const workoutsReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
