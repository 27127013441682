import React from 'react';
import { Row, Col } from 'antd';
import Trainers from './trainers';

const Grid = () => {
  
  return (
    <Row gutter={16}>
      <Col span={8}>
        <Trainers />
      </Col>
      <Col span={8}>
        <Trainers />
      </Col>
      <Col span={8}>
        <Trainers />
      </Col>
    </Row>
  );
}

export default Grid;