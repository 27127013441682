import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import MainLayout from "../layout/index";
import TrainersList from "./list";
import TrainersAdd from "./add";
import Item from "./item";
import { fetchList } from "actions/clubs";
import { fetchList as plansList } from "actions/plans";

const Trainers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchList());
    dispatch(plansList());
  }, []);

  return (
    <MainLayout>
      <Helmet>
        <title>Тренеры</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<TrainersList />} />
        <Route exact path="/add" element={<TrainersAdd />} />
        <Route exact path="/:id" element={<Item />} />
      </Routes>
    </MainLayout>
  );
};

export default Trainers;
