import React from "react";
import { useSelector } from "react-redux";
import { Space, Statistic } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const WorkoutsStat = (p) => {
  return (
    <Space size="middle">
      <Statistic title="Тренировок" value={p.item ? p.item.counts : "0"} />
      <Statistic title="В ожидании" value={p.item ? p.item.recent : "0"} />
      <Statistic title="Успешных" value={p.item ? p.item.success : "0"} />
      <Statistic title="Отмененных" value={p.item ? p.item.canceled : "0"} />
    </Space>
  );
};

const BillsStat = (p) => {
  return (
    <Space size="middle">
      <Statistic title="Продаж" value={p.item ? p.item.counts : "0"} />
      <Statistic
        title="Всего тернировок"
        value={p.item ? p.item.wcounts : "0"}
      />
    </Space>
  );
};

const ClientsStat = (p) => {
  let percent = (p.item.count / p.item.prev_count).toFixed(2);

  let icon_color = { color: null, icon: null, koff: null };
  if (percent > 1) {
    if (p.item.prev_count == 0) {
      icon_color.koff = "В прошлом месяце 0";
      icon_color.color = "gray";
      icon_color.icon = null;
    } else {
      icon_color.koff = percent;
      icon_color.color = "#3f8600";
      icon_color.icon = <ArrowUpOutlined />;
    }
  } else if (percent < 1) {
    if (p.item.prev_count == 0) {
      icon_color.koff = "В прошлом месяце 0";
      icon_color.color = "gray";
      icon_color.icon = null;
    } else {
      icon_color.koff = percent;
      icon_color.color = "red";
      icon_color.icon = <ArrowDownOutlined />;
    }
  }

  return (
    <Space>
      <Statistic
        title="Клиентов в прошлом месяце"
        value={p.item ? p.item.prev_count : "0"}
      />
      <Statistic
        title="Клиентов в этом месяце"
        value={p.item ? p.item.count : "0"}
      />
      <Statistic
        title="Коэффициент клиенты этого / клиенты прошлого"
        prefix={icon_color.icon}
        valueStyle={{ color: icon_color.color }}
        value={icon_color.koff}
      />
      <Statistic title="Новые клиенты" value={p.item.new_clients_count} />
      <Statistic title="Те кто ходил в прошлом месяце" value={p.item.count} />
      <Statistic
        title="Те кто не ходил в прошлом месяце и вернулся опять"
        value={p.item.clients_who_go_prev_month_and_this_month}
      />
    </Space>
  );
};

const Statistics = () => {
  const item = useSelector((state) => state.trainers.statistic);

  return (
    <Space direction="vertical">
      <h3>Тренировки</h3>
      <WorkoutsStat item={item.workouts} />
      <h3>Клиенты</h3>
      <ClientsStat item={item.clients} />
      <h3>Продажи</h3>
      <BillsStat item={item.bills} />
    </Space>
  );
};

export default Statistics;
