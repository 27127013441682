import React from 'react';
import { useSelector } from 'react-redux';
import { Card, Space, Badge } from "antd";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);


const ClientsList = (c) => {
  return (
    c.map((i) => (<div key={i.id}>{i.name + " " + i.lastname + " " + i.phone}</div>))
  )
}

const Status = (status) => {
  if (status === "recent") {
    return "В ожидании"
  }
  if (status === "canceled") {
    return "Отмененная"
  }
  if (status === "success") {
    return "Успешная"
  }
}

const Color = (status) => {
  if (status === "recent") {
    return "orange"
  }
  if (status === "canceled") {
    return "red"
  }
  if (status === "success") {
    return "green"
  }
}



const WorkoutsList = (w) => {

  return (
    w.map((i) => (
      <Badge.Ribbon text={Status(i.status)} color={Color(i.status)} key={i.id}>
        <Card key={i.id} bordered={false}>
          <div><b>{dayjs(i.date).format("DD.MM.YY HH:ss")}</b></div>
          <div>{i.plan.name}</div>
          {ClientsList(i.clients)}
        </Card>
      </Badge.Ribbon>
      ))
  )
}


const TrainersWorkouts = () => {

  const list = useSelector(state => state.trainers.workouts)

    return (
      <div>
        <Space direction="vertical" style={{display: 'flex'}}>
          {WorkoutsList(list)}
        </Space>
      </div>
    );

}

export default TrainersWorkouts;