import { combineReducers } from 'redux';
import {
  BILLS_LIST,
  BILLS_ITEM,
  BILLS_ADD
} from "constants/bills";

export function list(state = [], action) {
    switch (action.type) {
        case BILLS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case BILLS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case BILLS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const billsReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
