import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input } from "antd";
import { fetchList } from "actions/clients";


const ClientsSearch = () => {

  const dispatch = useDispatch();

  const onFinish = (values) => {
    dispatch(fetchList(values))
  };

  return (
      <Form
        autoComplete="off"
        onFinish={onFinish}
        labelCol={{ span: 4 }}
      >
      <Form.Item
        name="name_phone"
        label="ФИО/Телефон"
        rules={[{ required: true, message: 'Введите данные для поиска' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Найти
        </Button>
      </Form.Item>
      <br/>
      <br/>
      </Form>
  );

}

export default ClientsSearch;