import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { fetchItem, updateItem } from "actions/clients";

const ClientsEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  let item = useSelector((state) => state.clients.item);

  useEffect(() => {
    dispatch(fetchItem(id));
    form.setFieldsValue(item);
  }, [item.id]);

  const onFinish = (values) => {
    let val = { ...values, id: item.id };
    dispatch(updateItem(val));
  };

  return (
    <Card
      type="inner"
      title="Редактировать клиента"
      extra={
        <Link to="/clients">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Имя"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите имя",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Фамилия"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Введите фамилию",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Отчество"
          name="middlename"
          rules={[
            {
              required: true,
              message: "Введите отчество",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              message: "Введите телефон",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Почта" name="email">
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ClientsEdit;
