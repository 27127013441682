import React from 'react';
import {connect} from "react-redux";
import { message } from 'antd';
import {
  SUCCESS_MESSAGE,
  ERRORE_MESSAGE
} from "constants/messages";

class MessageProvider extends React.Component {

  componentDidUpdate() {
    this.showMessage();
  }

  showMessage = () => {
    const { success, error } = this.props;
    if (success) {
      message.success(success, 1);
      this.clearMessage();
    }
    if (error) {
      if (typeof error === 'object') {
        Object.keys(error).map(function(objectKey, index) {
            var value = error[objectKey];
            message.error(value, 1);
        });
      }
      else {
        message.error(error, 4);
      }
      this.clearMessage();
    }
  }

  clearMessage = () => {
    this.props.resetSuccess(null);
    this.props.resetError(null);
  }

  render () {
    return (
      <div>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    success: state.messages.success,
    error: state.messages.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetSuccess: (message) => dispatch({
      type: SUCCESS_MESSAGE,
      message
    }),
    resetError: (message) => dispatch({
      type: ERRORE_MESSAGE,
      message
    }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessageProvider);
