import { combineReducers } from 'redux';
import {
  DIVISION_REPORT
} from "constants/reports";
import dayjs from 'dayjs';

export function division(state = [], action) {
    switch (action.type) {
        case DIVISION_REPORT:
            return action.payload;
        default:
            return state;
    }
}

export const reportsReducers = () => combineReducers({
    division: division
});
