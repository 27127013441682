import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table, Typography } from "antd";
import { history } from "store";
import { fetchList } from "actions/admins";

const { Text } = Typography;

const AdminsTable = () => {
  const dispatch = useDispatch();
  const list = useSelector((state) => state.admins.list);

  useEffect(() => {
    dispatch(fetchList({ role: "admin" }));
  }, []);

  const columns = [
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record.active == false ? (
          <Text disabled>{record.name}</Text>
        ) : (
          record.name
        ),
    },
    {
      title: "Фамилия",
      dataIndex: "lastname",
      key: "lastname",
      render: (_, record) =>
        record.active == false ? (
          <Text disabled>{record.lastname}</Text>
        ) : (
          record.lastname
        ),
    },
    {
      title: "Отчество",
      dataIndex: "middlename",
      key: "middlename",
      render: (_, record) =>
        record.active == false ? (
          <Text disabled>{record.middlename}</Text>
        ) : (
          record.middlename
        ),
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
      render: (_, record) =>
        record.active == false ? (
          <Text disabled>{record.phone}</Text>
        ) : (
          record.phone
        ),
    },
    {
      title: "Почта",
      dataIndex: "email",
      key: "email",
      render: (_, record) =>
        record.active == false ? (
          <Text disabled>{record.email}</Text>
        ) : (
          record.email
        ),
    },
  ];

  return (
    <div>
      <Table
        className={"data-table"}
        pagination={false}
        bordered
        rowKey="id"
        columns={columns}
        dataSource={list}
        locale={{ emptyText: "Нет данных" }}
        onRow={({ id }) => ({
          onClick: () => {
            history.push(`/admins/${id}`);
          },
        })}
      />
    </div>
  );
};

export default AdminsTable;
