import { combineReducers } from 'redux';
import {
  MENU_CURRENT,
  GLOBAL_MONTH
} from "constants/global";
import dayjs from 'dayjs';


export function current(state = "main", action) {
    switch (action.type) {
        case MENU_CURRENT:
            return action.payload;
        default:
            return state;
    }
}

export function month(state = dayjs().format("DD.MM.YYYY"), action) {
    switch (action.type) {
        case GLOBAL_MONTH:
            return action.payload;
        default:
            return state;
    }
}

export const globalReducers = () => combineReducers({
    current: current,
    month: month
});
