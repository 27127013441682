import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { fetchItem, updateItem } from "actions/cities";

const CitiesEdit = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const item = useSelector((state) => state.cities.item);
  const divisions = useSelector((state) => state.companies.list);

  useEffect(() => {
    dispatch(fetchItem(id));
    form.setFieldsValue(item);
  }, [item.id]);

  const onFinish = (values) => {
    const val = { ...values, id: item.id };
    dispatch(updateItem(val));
  };

  return (
    <Card
      type="inner"
      title="Изменить город"
      extra={
        <Link to="/cities">
          <Button type="primary" className="add-btn">
            Назад
          </Button>
        </Link>
      }
    >
      <Form
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        labelCol={{
          span: 4,
        }}
      >
        <Form.Item
          label="Название"
          name="name"
          rules={[
            {
              required: true,
              message: "Введите название города",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Изменить
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default CitiesEdit;
