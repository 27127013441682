import {Link} from "react-router-dom";
import {Row, Col, Button} from "antd";
import TrainersTable from "./table";

const TrainersList = () => {
    return (
        <div>
          <h1>Тренеры</h1>
          <Row >
            <Col span={24}>
              <Link to="/trainers/add"><Button type="primary" className="add-btn">Создать</Button></Link>
            </Col>
          </Row>
          <TrainersTable />
        </div>
    );

}

export default TrainersList;