import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Menu } from 'antd';
import { set_current_menu } from "actions/global"


const items = [
  {
    label: (<NavLink to="/">Главная</NavLink>),
    key: 'main',
  },
  {
    label: (<NavLink to="/workouts">Тренировки</NavLink>),
    key: 'workouts',
  },
  {
    label: (<NavLink to="/bills">Продажи тренеров</NavLink>),
    key: 'bills',
  },
  {
    label: (<NavLink to="/clients">Клиенты</NavLink>),
    key: 'clients',
  },
  {
    label: 'Справочники',
    key: 'SubMenu',
    children: [
      {
        label: (<NavLink to="/admins">Администраторы</NavLink>),
        key: 'admins',
      },
      {
        label: (<NavLink to="/companies">Фирмы</NavLink>),
        key: 'divisions',
      },
      {
        label: (<NavLink to="/cities">Города</NavLink>),
        key: 'cities',
      },
      {
        label: (<NavLink to="/clubs">Клубы</NavLink>),
        key: 'clubs',
      },
      {
        label: (<NavLink to="/trainers">Тренеры</NavLink>),
        key: 'trainers',
      },
      {
        label: (<NavLink to="/plans">Форматы тренировок</NavLink>),
        key: 'plans',
      }
    ]
  },
  {
    label: 'Отчеты',
    key: 'SubMenu1',
    children: [
      {
        label: (<NavLink to="/reports/trainers">Тренеры</NavLink>),
        key: 'reports:1',
      },
      {
        label: (<NavLink to="/reports/clients">Клиенты</NavLink>),
        key: 'reports:2',
      }
    ]
  },
  {
    label: (<NavLink to="/logout">Выход</NavLink>),
    key: 'logout',
  }
];




const Mainmenu = () => {

  const dispatch = useDispatch();
  const key = useSelector(state => state.global.current)

  const onClick = (e) => {
    dispatch(set_current_menu(e.key))
  };
    
  return <Menu mode="horizontal" items={items} selectedKeys={key} onClick={onClick} />
}

export default Mainmenu;
