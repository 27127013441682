import { isLoggedIn, logout } from "actions/login";
import * as message from "actions/messages";

export default class AuthMiddleware {
  constructor(store, history) {
    this.store = store;
    this.history = history;
  }

  onSync(promise) {
    return promise;
  }

  onResponseError(err) {
    if (err.response.status === 400) {
      this.store.dispatch(message.error(JSON.parse(err.response.data).error));
    } else if (err.response.status === 401) {
      if (isLoggedIn()) {
        this.history.push("/login");
        logout();
        this.store.dispatch(message.error(err.response.data));
      } else {
        this.store.dispatch(message.error(err.response.data));
      }
    } else if (err.response.status === 500) {
      this.store.dispatch(message.error("Ошибка 500 (Ошибка на сервере)"));
    } else if (err.response.status === 404) {
      this.store.dispatch(
        message.error("Ошибка 404 (Неправильный запрос на сервер)")
      );
    } else if (err.response.status === 403) {
      this.store.dispatch(message.error(err.response.data));
    } else if (err.response.status === 409) {
      this.history.push("/not_found");
    } else {
      const errors_list = JSON.parse(err.response.data);
      const st = this.store;
      Object.keys(errors_list).forEach(function (elem, i) {
        st.dispatch(message.error(errors_list[elem]));
      });
    }
    throw err;
  }
}
