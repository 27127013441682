import {Link} from "react-router-dom";
import {Row, Col, Button} from "antd";
import AdminsTable from "./table";

const AdminsList = () => {
    return (
        <div>
          <h1>Администраторы</h1>
          <Row >
            <Col span={24}>
              <Link to="/admins/add"><Button type="primary" className="add-btn">Создать</Button></Link>
            </Col>
          </Row>
          <AdminsTable />
        </div>
    );

}

export default AdminsList;