import { combineReducers } from 'redux';
import {
  CITIES_LIST,
  CITIES_ITEM,
  CITIES_ADD
} from "constants/cities";

export function list(state = [], action) {
    switch (action.type) {
        case CITIES_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case CITIES_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case CITIES_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const citiesReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
