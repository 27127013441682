import React from 'react';
import { Route, Routes } from 'react-router-dom';

import PageNotFound from './not_found';
import LoginComponent from './login/index';
import LogoutComponent from './login/logout';
import CodeComponent from './login/code';
import PrivateRoute from './login/route';
import Dashboard from 'components/dashboard'
import Profile from 'components/profile';
import Cities from 'components/cities'
import Clubs from 'components/clubs'
import Trainers from 'components/trainers'
import Plans from 'components/plans'
import Clients from 'components/clients'
import Admins from 'components/admins'
import Bills from 'components/bills'
import Workouts from 'components/workouts'
import Acceptlink from 'components/accept';
import Companies from 'components/companies';
import ReportsTrainers from 'components/reports/trainers'

class Main extends React.Component {

  render() {
    return (
      
        <Routes>
          <Route path="/login" element={<LoginComponent/>}/>
          <Route path="/code" element={<CodeComponent/>}/>
          <Route path="/acceptlink/:id" element={<Acceptlink/>}/>

          <Route element={<PrivateRoute />}>
            <Route path="/" element={<Dashboard />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/logout" element={<LogoutComponent />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/cities/*" element={<Cities />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/companies/*" element={<Companies />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/clubs/*" element={<Clubs />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/trainers/*" element={<Trainers />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/plans/*" element={<Plans />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/clients/*" element={<Clients />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/admins/*" element={<Admins />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/bills/*" element={<Bills />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/workouts/*" element={<Workouts />} />
          </Route>

          <Route element={<PrivateRoute />}>
            <Route path="/reports/trainers/*" element={<ReportsTrainers />} />
          </Route>

          <Route path='*' element={<PageNotFound />} />
        </Routes>
      
    )
  }
}

export default Main
