import React  from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { Card, Form, Button, Input, Select } from "antd";
import { addItem } from "actions/plans";

const PlansAdd = (props) => {
  const dispatch = useDispatch();
  const clubs = useSelector(state => state.clubs.list);

  const onFinish = (values) => {
    dispatch(addItem(values))
  }

  return (
        <Card type="inner" title="Создать формат тренировки" extra={<Link to="/plans"><Button type="primary" className="add-btn">Назад</Button></Link>}>
        <Form
        onFinish={onFinish}
        autoComplete="off"
        labelCol={{
          span: 4,
        }}
      >
    <Form.Item
      label="Название"
      name="name"
      rules={[
        {
          required: true,
          message: 'Введите название',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Описание"
      name="description" 
    >
      <Input.TextArea />
    </Form.Item>

    <Form.Item>
      <Button type="primary" htmlType="submit">
        Создать
      </Button>
    </Form.Item>
  </Form>

      </Card>
  );
}

export default PlansAdd;
