import {
  SUCCESS_MESSAGE,
  ERRORE_MESSAGE
} from "constants/messages";

export function success(message) {
  return {
    type: SUCCESS_MESSAGE,
    message
  };
}

export function error(message) {
  return {
    type: ERRORE_MESSAGE,
    message
  };
}
