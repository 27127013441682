import { combineReducers } from 'redux';
import {
  ADMINS_LIST,
  ADMINS_ITEM,
  ADMINS_ADD
} from "constants/admins";

export function list(state = [], action) {
    switch (action.type) {
        case ADMINS_LIST:
            return action.payload;
        default:
            return state;
    }
}

export function item(state = [], action) {
    switch (action.type) {
        case ADMINS_ITEM:
            return action.payload;
        default:
            return state;
    }
}

export function add(state = [], action) {
    switch (action.type) {
        case ADMINS_ADD:
            return action.payload;
        default:
            return state;
    }
}

export const adminsReducers = () => combineReducers({
    list: list,
    item: item,
    add: add,
});
