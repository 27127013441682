import axios from 'axios';
import {
  DIVISION_REPORT
} from "constants/reports";
import { API_URL } from 'config/conf';
import * as message from 'actions/messages';
import { history } from "store";

const API_SECTION = "reports/trainers";

export function fetchDivisionsReport(month) {
  return (dispatch) => {
    axios.get(`${API_URL}${API_SECTION}`, {params: {month: month}})
      .then((response) => {
          dispatch({type: DIVISION_REPORT, payload: response.data});
      })
  };
}