import React from "react";
import Mainmenu from "./mainmenu";
import { Layout as Header } from 'antd';

class AppHeader extends React.Component {

  render() {
    return (
      <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Mainmenu />
      </Header>
    );
  }
}

export default AppHeader;
