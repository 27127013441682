import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from '@superilya/connected-react-router-18'
import { thunk } from 'redux-thunk';
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import rootReducer from './reducers';

export const history = createBrowserHistory({ window });

const enhancers = [];
const middleware = [
  thunk,
  routerMiddleware(history)
];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export default function configStore(initialState) {
  const store = createStore(
      rootReducer(history),
      initialState,
      composedEnhancers
  );

  return store;
}
