let API_URL = null;
let API_URL_ACCEPTANCE = null;
let REACT_APP_ENV = null;

if (process.env.NODE_ENV === "production") {
  console.log("App environment is set to " + process.env.NODE_ENV);
  API_URL = "https://backend.fitteh.ru/api/v1/";
  API_URL_ACCEPTANCE = "https://fitteh.ru.ru/";
} else {
  REACT_APP_ENV = "development";
  console.log("App environment is set to " + process.env.NODE_ENV);
  API_URL = "http://localhost:3000/api/v1/";
  API_URL_ACCEPTANCE = "http://localhost:4000/";
}

export { API_URL, REACT_APP_ENV, API_URL_ACCEPTANCE };
