import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {Helmet} from "react-helmet";
import MainLayout from "../layout/index";
import List from "./list";
import Add from "./add";
import Edit from "./edit";

const Companies = () => {
  return (
    <MainLayout>
      <Helmet>
        <title>Фирмы</title>
      </Helmet>
      <Routes>
        <Route exact path="/" element={<List/>} />
        <Route exact path="/add" element={<Add/>} />
        <Route exact path="/:id" element={<Edit/>} />
      </Routes>
    </MainLayout>
  );
}

export default Companies;

