import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form, Input, Space } from "antd";
import AuthLayout from "../layout/auth_layout";
import { chechcode, repeatcall } from "actions/login";

const CodeComponent = () => {
  const dispatch = useDispatch();
  let phone = useSelector((state) => state.login.phone);
  let ucallerid = useSelector((state) => state.login.ucallerid);

  const onFinish = (values) => {
    let val = { ...values, phone: phone };
    dispatch(chechcode(val));
  };

  const repeatCall = () => {
    dispatch(repeatcall(ucallerid));
  };

  return (
    <AuthLayout>
      <Form
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="on"
      >
        <h2>Введите код</h2>
        <div className="form__note form__note_centered">
          Введите 4 последние цифры номера телефона, который позвонит вам на
          номер {phone}.
        </div>
        <Form.Item
          label="Код"
          name="code"
          rules={[
            {
              required: true,
              message: "Введите код",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Space>
            <Button type="primary" htmlType="submit">
              Вход
            </Button>

            <Button type="primary" onClick={() => repeatCall()}>
              Повторить звонок
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};

export default CodeComponent;
